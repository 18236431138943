<script lang="ts">
</script>
<script setup lang="ts">
import AppHeader from '@/components/commons/AppHeader/AppHeader.vue'
import BottomNavigation from '@/components/commons/BottomNavigation/BottomNavigation.vue'
import { computed } from '@vue/reactivity'
import { useRouter, useSeoMeta } from 'nuxt/app'

const router = useRouter();
const currentPath = computed(
  () => `https://nekonare.website${router.currentRoute.value.path}`
);
const iFrameCode: string = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KJS8B4P" height="0" width="0" style="display:none;visibility:hidden"></iframe>'

useSeoMeta({
  ogUrl: currentPath,
})
</script>
<template>
  <!-- Google Tag Manager (noscript) -->
  <noscript v-html="iFrameCode" />
  <!-- End Google Tag Manager (noscript) -->
  <v-app>

    <AppHeader />

    <v-main>
      <slot />
    </v-main>

    <BottomNavigation />

  </v-app>
</template>
