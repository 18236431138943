<script lang="ts">
export default {
  name: 'BottomNavigation',
}
</script>
<script lang="ts" setup>
</script>
<template>
  <v-bottom-navigation :grow="true">
    <v-btn size="x-small" to="/about-site">
      <v-icon>mdi-web</v-icon>
      <span v-if="!$vuetify.display.smAndDown">当サイトについて</span>
    </v-btn>
    <v-btn size="x-small" to="/guide">
      <v-icon>mdi-television-guide</v-icon>
      <span v-if="!$vuetify.display.smAndDown">利用ガイド</span>
    </v-btn>
    <v-btn size="x-small" to="/contact">
      <v-icon>mdi-contacts</v-icon>
      <span v-if="!$vuetify.display.smAndDown">お問い合わせ</span>
    </v-btn>
  </v-bottom-navigation>
</template>