<script lang="ts">
export default {
  name: 'AppHeader',
}
</script>
<script lang="ts" setup>
import { ref } from 'vue'
const isDev: boolean = import.meta.env.DEV
const drawer = ref(false)
</script>
<template>
  <v-app-bar>
    <v-app-bar-nav-icon @click="drawer = !drawer" />
    <v-app-bar-title text="nekonare(仮)" />
  </v-app-bar>

  <v-navigation-drawer v-model="drawer" :temporary="true">
    <v-list>
      <v-list-item :nav="true" prepend-icon="mdi-home" to="/">
        <v-list-item-title>トップ</v-list-item-title>
      </v-list-item>
      <v-list-group value="Learn">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="mdi-comment-search">
            <v-list-item-title>知る</v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item :nav="true" prepend-icon="mdi-open-in-app" to="/learn/cat-subsidy">
          <v-list-item-title>自治体</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isDev" :nav="true" prepend-icon="mdi-open-in-app" to="/learn/lost-cat">
          <v-list-item-title>迷い猫</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isDev" :nav="true" prepend-icon="mdi-open-in-app" to="/learn/lost-cat-contact">
          <v-list-item-title>ペットの捜索</v-list-item-title>
        </v-list-item>
        <v-list-item :nav="true" prepend-icon="mdi-open-in-app" to="/learn/ubiquitous">
          <v-list-item-title>言葉</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group value="Seek">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="mdi-map-search-outline">
            <v-list-item-title>探す</v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item :nav="true" prepend-icon="mdi-open-in-app" to="/seek/furusatonouzei">
          <v-list-item-title>ふるさと納税</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-item :nav="true" prepend-icon="mdi-link-variant" to="/link">
        <v-list-item-title>リンク</v-list-item-title>
      </v-list-item>
      <v-list-item :nav="true" prepend-icon="mdi-update" to="/whats-new">
        <v-list-item-title>更新情報</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>